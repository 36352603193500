<mat-sidenav-container>
  <mat-sidenav id="sidenav" #sidenav>
    <mat-nav-list>
      <a mat-list-item (click)="sidenav.toggle()"><mat-icon color="accent">close</mat-icon></a>
      <a mat-list-item routerLink="/"><img matListItemIcon src="assets/logo.svg"><span matListItemLine class="primary">{{appName}}</span></a>
      <a mat-list-item routerLink="/admin" *ngIf="authService.isAdmin"><mat-icon matListItemIcon>admin_panel_settings</mat-icon><span matListItemLine>Admin</span></a>
      <a mat-list-item routerLink="/profile" *ngIf="authService.isLoggedIn"><mat-icon matListItemIcon>account_circle</mat-icon><span matListItemLine>Profile</span></a>
      <a mat-list-item routerLink="/settings" *ngIf="authService.isLoggedIn"><mat-icon matListItemIcon>settings</mat-icon><span matListItemLine>Settings</span></a>
      <a mat-list-item (click)="authService.logout()" *ngIf="authService.isLoggedIn"><mat-icon matListItemIcon color="warn">logout</mat-icon><span matListItemLine class="warn">Logout</span></a>
      <div class="spacer"></div>
      <a mat-list-item routerLink="/logs"><mat-icon matListItemIcon>bug_report</mat-icon><span matListItemLine>Logs</span></a>
      <a mat-list-item routerLink="/about"><mat-icon matListItemIcon>info</mat-icon><span matListItemLine>About</span></a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div id="page">
      <mat-toolbar color="primary" id="navbar" class="mat-elevation-z6">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <span class="menu-spacer"></span>
        <div>
          <a mat-button [routerLink]="'/'" *ngIf="!swUpdates.isUpdateAvailable">{{appName}}</a>
          <a mat-stroked-button [routerLink]="'/about'" class="update" *ngIf="swUpdates.isUpdateAvailable">Update</a>
        </div>
        <mat-spinner *ngIf="activityService.isActive$ | async" color="accent" diameter="34"></mat-spinner>
        <div fxFlex fxLayout fxLayoutAlign="end">
          <a mat-button [routerLink]="'/profile'" *ngIf="authService.isLoggedIn">{{userService.currentUser.displayName}}</a>
          <a mat-button [routerLink]="'/login'" *ngIf="!authService.isLoggedIn">Login</a>
        </div>
      </mat-toolbar>
      <div id="main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
