<div style="text-align:center">
  <img alt="Logo" src="assets/logo.svg">
  <h1>{{appName}}</h1>
  <div *ngIf="swUpdates.isEnabled">
    <p>
      <label>Last checked: </label>
      <span>{{swUpdates.lastChecked$ | async}}</span>
    </p>
    <p class="spinner-wrapper">
      <span *ngIf="!swUpdates.isUpdateAvailable">No update available</span>
      <button mat-flat-button color="accent" (click)="swUpdates.updateNow()" *ngIf="swUpdates.isUpdateAvailable" [disabled]="swUpdates.isUpdating">Update</button>
      <mat-spinner *ngIf="swUpdates.isUpdating" color="accent" diameter="34"></mat-spinner>
    </p>
  </div>
  <p>
    <label>Version: </label>
    <span>{{version}}</span>
  </p>
  <p>
    <label>Build Date: </label>
    <span>{{buildDate}}</span>
  </p>
  <p>
    This is a progressive web app (PWA) that helps you to keep track of turns for activities among users.
  </p>
</div>
