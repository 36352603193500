<mat-card *ngIf="settingsForm" [formGroup]="settingsForm">
  <mat-card-header>
    <mat-card-title>Settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section>
      <mat-slide-toggle formControlName="push">Push Notifications</mat-slide-toggle>
      <div *ngIf="pushService.isBlocked" class="warn">Blocked on this device</div>
      <div *ngIf="me.enablePushNotifications">
        <p>
          <button class="test" mat-flat-button color="primary" (click)="sendTestMessageToThisDevice()" [disabled]="!pushService.PushEnabled">Test This Device</button>
        </p>
        <p>
          <button class="test" mat-flat-button color="warn" (click)="closeTestMessageToThisDevice()" [disabled]="!pushService.PushEnabled">Close This Device</button>
        </p>
        <p>
          <button class="test" mat-flat-button color="accent" (click)="sendTestMessageToAllDevices()" [disabled]="!pushService.PushEnabled">Test All Devices</button>
        </p>
      </div>
    </section>
    <section>
      <mat-form-field>
        <mat-label>Snooze Hours</mat-label>
        <input matInput type="number" min="1" max="255" step="1" required formControlName="snoozeHours" [errorStateMatcher]="immediateErrors">
        <mat-error>Must be between 1 and 255</mat-error>
      </mat-form-field>
    </section>
  </mat-card-content>
</mat-card>
