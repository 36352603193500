<div *ngIf="anyDisabledActivities" id="disabled-toggle">
  <mat-slide-toggle (change)="filterActivities($event.checked)" [checked]="me.showDisabledActivities">Show Disabled</mat-slide-toggle>
</div>
<table *ngIf="activities.data.length" mat-table [dataSource]="activities" class="mat-elevation-z8" matSort>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity</th>
    <td mat-cell *matCellDef="let activity">{{activity.name}}</td>
  </ng-container>
  <ng-container matColumnDef="currentTurnUserDisplayName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Turn</th>
    <td mat-cell *matCellDef="let activity">
      <span *ngIf="activity.currentTurnUserDisplayName" [class.primary]="activity.currentTurnUserId === me.id">{{activity.currentTurnUserDisplayName}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="dueDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Due</th>
    <td mat-cell *matCellDef="let activity">
      <app-timer *ngIf="activity.dueDate" [due]="activity.dueDate" [maxTimeComponents]="2"></app-timer>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let activity; columns: displayedColumns" class="activity-row" (click)="viewDetails(activity)" [class.disabled]="activity.isDisabled"></tr>
</table>

<div id="bottom-fab-container">
  <button mat-fab color="primary" (click)="addActivity()" matTooltip="Add Activity" matTooltipPosition="before"><mat-icon>add</mat-icon></button>
</div>
