<table *ngIf="users.length" mat-table [dataSource]="users" class="mat-elevation-z8" matSort>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let user">{{user.name}}</td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
    <td mat-cell *matCellDef="let user">{{roles[user.role]}}</td>
  </ng-container>
  <ng-container matColumnDef="password">
    <th mat-header-cell *matHeaderCellDef>Password</th>
    <td mat-cell *matCellDef="let user">
      <button *ngIf="user.id !== myUserId" mat-stroked-button color="warn" (click)="reset(user)" [disabled]="isLoading">Reset</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['name','role','password']"></tr>
  <tr mat-row *matRowDef="let user; columns: ['name','role','password']"></tr>
</table>
